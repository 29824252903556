<template>
  <div class="app-content content" :class="[{'show-overlay': $store.state.app.shallShowOverlay}]">
    <div class="content-overlay"/>
    <div class="header-navbar-shadow"/>
    <div class="content-wrapper">
      <div class="content-body">
        <transition name="zoom-fade" mode="out-in">
          <div class="card">
            <div class="card-body">
              <div class="row mb-2 justify-content-md-end">
                <div class="col-12 col-md-3">
                  <router-link
                      v-if="$can('manage', 'orders')"
                      class="btn btn-primary w-100"
                      :to="{name: 'order-create'}"
                  >
                    Добавить Заказ
                  </router-link>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-start">
                  <span class="text-muted">
                    Показано с {{ orders.from }} по {{ orders.to }} из {{ orders.total }} записей
                  </span>
                </div>
                <div class="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
                  <b-pagination
                      v-model="currentPage"
                      :total-rows="orders.total"
                      :per-page="orders.per_page"
                      first-number
                      last-number
                      class="mb-0 mt-1 mt-sm-0"
                      prev-class="prev-item"
                      next-class="next-item"
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18"/>
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18"/>
                    </template>
                  </b-pagination>
                </div>
              </div>
              <div class="position-relative table-responsive">
                <table class="table b-table">
                  <thead>
                  <!---->
                  <tr>
                    <th>#</th>
                    <th>Дата</th>
                    <th v-if="$can('read', 'users')">Покупатель</th>
                    <th>ТТН</th>
                    <th>Статус</th>
                    <th>К-во</th>
                    <th>Вес (кг)</th>
                    <th v-if="$can('read', 'payments')">Сумма</th>
                    <th>Сумма наложки</th>
                    <th>Комментарий</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                      v-for="order in orders.data"
                      :key="order.id"
                      :class="getOrderStatusClass(order.status)"
                  >
                    <td>
                      <router-link :to="{ name: 'order-details', params: { id: order.id }}" class="font-weight-bold">
                        #{{ order.id }}
                      </router-link>
                    </td>
                    <td>{{ $formatters.formattedDate(order.created_at) }}</td>
                    <td v-if="$can('read', 'users')" style="white-space: nowrap">
                      {{ order.user.name }} {{ order.user.surname }} <br>
                      <small>({{ order.user.company_name }})</small>
                    </td>
                    <td :class="order.ttn && order.ttn.length > 25 && 'font-small-2'">{{ order.ttn }}</td>
                    <td>
                      <v-select
                          v-if="statuses.length > 0"
                          v-model="order.status"
                          :options="statuses"
                          label="name"
                          class="style-chooser"
                          :clearable="false"
                          :disabled="![1,2,3,8].includes(order.status.id)"
                          @input="updateStatus(order.id, order.status.id)"
                      >
                      </v-select>
                    </td>
                    <td>{{ order.qty }}</td>
                    <td>{{ order.weight / 1000 }}</td>
                    <td v-if="$can('read', 'payments')">
                    <span class="amount" v-for="(item, index) in order.sum" :key="index">
                      <span class="amount">{{ item.amount }}</span> <span class="currency">{{ item.currency }}</span>  <br/>
                    </span>
                    </td>
                    <td>{{ order.client_price }}</td>
                    <td :class="order.comments && order.comments.length > 25 && 'font-small-2'">
                    <span :class="order.comments && order.comments.length > 20 && 'text-dashed'">
                      {{ order.comments && order.comments.length > 25 ? order.comments.substring(0, 25) + '...' : order.comments }}
                    </span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="mx-2 mb-2">
                <div class="row">
                  <div class=" col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-start">
                  <span class="text-muted">
                    Показано с {{ orders.from }} по {{ orders.to }} из {{ orders.total }} записей
                  </span>
                  </div>
                  <div class="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
                    <b-pagination
                        v-model="currentPage"
                        :total-rows="orders.total"
                        :per-page="orders.per_page"
                        first-number
                        last-number
                        class="mb-0 mt-1 mt-sm-0"
                        prev-class="prev-item"
                        next-class="next-item"
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18"/>
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18"/>
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import { BPagination } from 'bootstrap-vue';
import vSelect from 'vue-select';

export default {
  name: 'Orders',
  components: {
    BPagination, vSelect,
  },
  data() {
    return {
      currentPage: 1,
      statuses: [],
      orders: {
        current_page: 1,
        data: [],
        from: null,
        to: null,
        total: null,
        per_page: 10,
      },
    };
  },
  watch: {
    async currentPage(newValue) {
      this.orders.current_page = newValue;
      await this.getOrders();
    },
  },
  async mounted() {
    await this.getOrderStatuses();
    await this.getOrders();
  },
  methods: {
    async getOrders() {
      this.orders = (await this.$api.orders.list({
        ...(this.orders.current_page > 1 && { page: this.orders.current_page }),
        limit: 25,
      })).data;
    },
    async getOrderStatuses() {
      this.statuses = (await this.$api.orders.statuses()).data;
    },
    getOrderStatusClass(status) {
      if (status.id === 2) {
        return 'row-green';
      }

      if (status.id === 1) {
        return 'row-warning';
      }

      return null;
    },
    async updateStatus(orderId, orderStatusId) {
      try {
        await this.$api.orders.statusUpdate(orderId, { status_id: orderStatusId });
        this.$toast.success(`Статус заказа #${orderId} успешно обновлен`);
      } catch (e) {
        // console.log(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

::v-deep .v-select {
  width: 220px;
}
.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.amount {
  white-space: nowrap;

  & span.amount {
    font-size: 18px;

  }

  & span.currency {
    font-size: 10px;
  }
}

.row-green {
  background-color: #e6ffe6;
}

.row-warning {
  background-color: #fff3cd;
}

.text-dashed {
  border-bottom: 1px dotted #6c6c6c;
  cursor: pointer;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
